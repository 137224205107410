body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* .ant-layout-header {
  color: #fff;
  background: #181818;
} */

.logo {
  float: left;
  color: #ffffff;
  font-size: large;
  font-weight: 500;
  padding-right: 20px;
  cursor: pointer;
}
html,
body {
  height: 100%;
}

.ant-btn-red:hover,
.ant-btn-red:focus {
  color: #fff;
  border-color: #ff182d;
  background: #ff4655;
}
.ant-btn-red {
  color: #fff;
  border-color: #ff0015;
  background: #ff182d;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-green:hover,
.ant-btn-green:focus {
  color: #fff;
  border-color: #50c900;
  background: #58db00;
}
.ant-btn-green {
  color: #fff;
  border-color: #55d400;
  background: #50c900;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-orange:hover,
.ant-btn-orange:focus {
  color: #fff;
  border-color: #dba800;
  background: #f1b900;
}
.ant-btn-orange {
  color: #fff;
  border-color: #d6a400;
  background: #dba800;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-violet:hover,
.ant-btn-violet:focus {
  color: #fff;
  border-color: #ee00ee;
  background: #ff00ff;
}
.ant-btn-violet {
  color: #fff;
  border-color: #d400d4;
  background: #ee00ee;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.ant-menu-custome,
.ant-menu-custome > .ant-menu-item:hover::after,
.ant-menu-custome > .ant-menu-submenu:hover::after,
.ant-menu-custome > .ant-menu-item-active::after,
.ant-menu-custome > .ant-menu-submenu-active::after,
.ant-menu-custome > .ant-menu-item-open::after,
.ant-menu-custome > .ant-menu-submenu-open::after,
.ant-menu-custome > .ant-menu-item-selected::after,
.ant-menu-custome > .ant-menu-submenu-selected::after {
  border-bottom: none;
  color: #fff;
}
.ant-menu.ant-menu-custome,
.ant-menu-custome .ant-menu-sub,
.ant-menu.ant-menu-custome .ant-menu-sub {
  color: #fff;
  background: #181818;
}
.ant-menu.ant-menu-custome .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-custome .ant-menu-item-selected {
  background-color: #ffc400;
}
.ant-menu-custome > .ant-menu-item-selected a {
  color: #ffffff;
}
.ant-menu-custome > .ant-menu-item:hover a {
  color: #ffffff;
}
.ant-menu-custome > .ant-menu-item:hover,
.ant-menu-custome > .ant-menu-submenu:hover,
.ant-menu-custome > .ant-menu-item-active,
.ant-menu-custome > .ant-menu-submenu-active,
.ant-menu-custome > .ant-menu-item-open,
.ant-menu-custome > .ant-menu-submenu-open,
.ant-menu-custome > .ant-menu-item-selected,
.ant-menu-custome > .ant-menu-submenu-selected {
  color: #fff;
}
.ant-menu-custome > .ant-menu-item:hover::after,
.ant-menu-custome > .ant-menu-submenu:hover::after,
.ant-menu-custome > .ant-menu-item-active::after,
.ant-menu-custome > .ant-menu-submenu-active::after,
.ant-menu-custome > .ant-menu-item-open::after,
.ant-menu-custome > .ant-menu-submenu-open::after,
.ant-menu-custome > .ant-menu-item-selected::after,
.ant-menu-custome > .ant-menu-submenu-selected::after {
  border-bottom: none;
}
.ant-menu-custome .ant-menu-item:hover::after,
.ant-menu-custome .ant-menu-submenu:hover::after,
.ant-menu-custome .ant-menu-item-active::after,
.ant-menu-custome .ant-menu-submenu-active::after,
.ant-menu-custome .ant-menu-item-open::after,
.ant-menu-custome .ant-menu-submenu-open::after,
.ant-menu-custome .ant-menu-item-selected::after,
.ant-menu-custome .ant-menu-submenu-selected::after {
  border-bottom: 0;
}
.ant-menu.ant-menu-custome .ant-menu-item-selected:hover,
.ant-menu-submenu-popup.ant-menu-custome .ant-menu-item-selected:hover,
.ant-menu.ant-menu-custome .ant-menu-item:hover,
.ant-menu-submenu-popup.ant-menu-custome .ant-menu-item:hover {
  background-color: #ffcd2b;
}
